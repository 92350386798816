/* eslint-disable complexity */
import { Card, Form, Spin } from 'antd';
import { includeCheckIn, includeLease, includeMonthly } from 'server/type-file';
import dayjs, { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { filter, groupBy, map, uniq } from 'lodash-es';
import { TrpcBase } from '../../../utils/trpc';
import { useUserAgent } from '../../../../src/utils/userAgent';
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { MonthTimePicker } from '../overview/MonthTimePicker';
import { useApartmentList } from '../overview/useApartmentList';
import { useOverviewState } from '../overview/useOverviewState';
import { calcTotal } from '../stat/calcTotal';
import { useBuildingConfig } from '../useBuildingConfig';
import { bigConstruct } from '../util/bigConstruct';
import { timestampStringToDayjs } from '../util/timestampStringToDayjs';
import { LineComponent } from './Line';
import { ColumnComponent } from './Column';
extend(isBetween);

export default function Flow({ type }: { type: 'used' | 'amount' }) {
	const { changeTitle, currentBuildingConfig, currentSelectedBuildingId } =
		useBuildingConfig();

	changeTitle('统计图表');
	const { formLayout, isPC } = useUserAgent();
	const [stateParams, setStateParams] = useOverviewState();

	const [form] = Form.useForm();
	const formTime = Form.useWatch('time', form) ?? dayjs();
	const time = timestampStringToDayjs(stateParams?.time);

	const withStartTime = startOfMonth(formTime.subtract(isPC ? 18 : 6, 'month'));
	const withEndTime = endOfMonth(formTime);

	const { list, isLoading } = useApartmentList({
		startTime: withStartTime.valueOf(),
		endTime: withEndTime.valueOf(),
		category: [includeMonthly, includeCheckIn, includeLease],
		chargeStatus: '0',
	});

	const { data } = TrpcBase.apartment.eleUsed.useQuery({
		startTime: withStartTime.clone().subtract(1, 'month').valueOf(),
		endTime: withEndTime.clone().valueOf(),
	});

	const transformData = groupBy(list, (i) =>
		dayjs(i.checkInTime).format('YYYY-MM'),
	);

	type statType = {
		yearMonth: string;
		month: string;
		year: string;
		value: number;
		category: string;
	};
	const stat = map(transformData, (grp, key) => {
		return {
			checkInTime: dayjs(grp?.[0]?.checkInTime),
			yearMonth: dayjs(grp?.[0]?.checkInTime).format('YYYY-MM'),
			month: dayjs(grp?.[0]?.checkInTime).format('MM'),
			...calcTotal(grp),
			quantity: grp.length,
			grp: grp,
			year: dayjs(grp?.[0]?.checkInTime).format('YYYY'),
		};
	})?.sort((r1, r2) => r1.checkInTime.diff(r2.checkInTime));

	const incomeLineData = stat.reduce((p, c) => {
		const vv = [
			{ label: '总计', point: 'total' },
			{ label: '租金', point: 'totalRent' },
			{ label: '电费', point: 'totalElectricCharge' },
			{ label: '热水费', point: 'totalHotWaterCharge' },
			{ label: '冷水费', point: 'totalColdWaterCharge' },
			{ label: '应收', point: 'shouldTotal' },
			{ label: '押金', point: 'totalDeposit' },
		].reduce((pv, ct) => {
			pv.push({
				yearMonth: c.yearMonth,
				month: `${c.month}`,
				year: c.year,
				value: c[ct.point as keyof typeof c] as number,
				category: ct.label,
			});
			return pv;
		}, [] as statType[]);

		return p.concat(vv);
	}, [] as statType[]);

	const incomeConfig = {
		data: incomeLineData,
		// padding: 'auto',
		xField: 'yearMonth',
		yField: 'value',
		seriesField: 'category',
		legend: {
			flipPage: false,
			maxRow: 3,
		},
		xAxis: {
			// type: 'timeCat',
			// tickCount: 5,
		},
	};

	const allIncomeConfig = {
		data: incomeLineData
			.filter((i) => i.category === '总计')
			?.sort((r1, r2) => r1.month - r2.month),
		// padding: 'auto',
		xField: 'month',
		yField: 'value',
		seriesField: 'year',
		legend: {
			flipPage: false,
			maxRow: 3,
		},
		xAxis: {
			// type: 'timeCat',
			// tickCount: 5,
		},
	};

	const usedLineData = stat.reduce((p, c) => {
		const vv = [
			{ label: '系统电量', point: 'electronUsed' },
			{ label: '热水用量', point: 'hotUsed' },
			{ label: '冷水用量', point: 'coldUsed' },
			{ label: '总水量', point: 'totalWater' },
		].reduce((pv, ct) => {
			pv.push({
				yearMonth: c.yearMonth,
				month: `${c.month}月`,
				year: c.year,
				value: c[ct.point as keyof typeof c] as number,
				category: ct.label,
			});
			return pv;
		}, [] as statType[]);

		return p.concat(vv);
	}, [] as statType[]);

	const waterData = usedLineData.filter((i) => i.category !== '系统电量');
	const waterUsedConfig = {
		data: waterData,
		xField: 'yearMonth',
		yField: 'value',
		seriesField: 'category',
		legend: {
			flipPage: false,
			maxRow: 3,
		},
		xAxis: {
			// type: 'timeCat',
			// tickCount: 5,
		},
	};

	const electronUsedData = usedLineData.filter(
		(i) => i.category === '系统电量',
	);

	// {yearMonth: '2023-07', month: '07月', year: '2023', value: Big2, category: '电量'}

	let mEle = data ?? [];

	const eleData1 = mEle?.map((i) => {
		const startM = dayjs(i.startTime).add(1, 'month');
		return {
			yearMonth: startM.format('YYYY-MM'),
			month: startM.format('MM月'),
			year: startM.format('YYYY'),
			value: bigConstruct(i.quantity).toNumber(),
			category: `${i.eleCustNumber} 电表`,
		} as statType;
	});

	const allGDJ = Object.entries(
		groupBy(eleData1, (i) => i?.yearMonth ?? 'none'),
	).map((ety) => {
		const [k, v] = ety;
		const s = v[0];
		return {
			...s,
			yearMonth: k,
			value: v
				.map((aa) => aa.value)
				.reduce((accumulator, cu) => {
					return Math.round(accumulator + cu);
				}, 0),
			category: '供电局电表总和',
		} as statType;
	});

	const elecAll = electronUsedData
		.concat(eleData1)
		.concat(allGDJ)

		.sort((a, b) =>
			dayjs(a.yearMonth, 'YYYY-MM').diff(dayjs(b.yearMonth, 'YYYY-MM')),
		);

	const categoryList = uniq(elecAll.map((a) => a.category));
	const mustSelected = ['供电局电表总和', '系统电量'];
	const selectedCategory = categoryList.reduce((p, v) => {
		return {
			// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
			...p,
			// biome-ignore lint/complexity/noUselessTernary: <explanation>
			[v]: mustSelected.includes(v) ? true : false,
		};
	}, {});

	const electronUsedConfig = {
		data: elecAll,
		xField: 'yearMonth',
		yField: 'value',
		seriesField: 'category',

		legend: {
			flipPage: false,
			maxRow: 3,

			selected: selectedCategory,
		},
		xAxis: {
			// type: 'timeCat',
			// tickCount: 5,
		},
	};

	const powerSupplyGap = map(
		filter(
			groupBy(
				elecAll.filter((k) => mustSelected.includes(k.category)),
				(i) => i.yearMonth,
			),
			(a) => {
				const a1 = a[0];
				const a2 = a[1];
				if (a1 && a2) {
					return true;
				}

				return false;
			},
		),
		(a) => {
			const a1 = a[0];
			const a2 = a[1];

			const convInt = (vv: any) => {
				if (vv?.toNumber) {
					return vv?.toNumber();
				}

				return vv;
			};

			return {
				...a1,
				value: convInt(a1.value) - convInt(a2.value),
			};
		},
	);

	const electronUsedGapConfig = {
		data: powerSupplyGap,
		xField: 'yearMonth',
		yField: 'value',

		legend: {
			flipPage: false,
			maxRow: 3,
		},
		xAxis: {},
	};

	return (
		<Card>
			<div className="flex flex-row gap-2 my-2">
				<div>快速链接:</div>
				<div>
					<a
						href="https://95598.csg.cn/#/sz/fee/feeService/electronicBill"
						target="_blank"
						rel="noreferrer"
					>
						南网在线
					</a>
				</div>
				<div>
					<a
						href="https://vx.hzwater.gd.cn/hzgsonline/pcserviceBill.do?goSubModule&function=first"
						target="_blank"
						rel="noreferrer"
					>
						惠州供水
					</a>
				</div>

				{['503610', '503619', '407324'].map((a) => {
					return (
						<div key={a}>
							<a
								href={`https://vx.hzwater.gd.cn/hzgsonline/pcserviceBill.do?goSubModule&function=waterBill&mainid=${a}`}
								target="_blank"
								rel="noreferrer"
							>
								惠州供水 - {a}
							</a>
						</div>
					);
				})}
			</div>

			<Form
				layout={formLayout}
				form={form}
				initialValues={{
					time: time,
					mode: stateParams?.mode,
				}}
				onValuesChange={(v, all) => {
					setStateParams((pre) => {
						return {
							...pre,
							...all,
							time: v?.time?.valueOf(),
						};
					});
				}}
				onFinish={(values) => {}}
			>
				<MonthTimePicker />
			</Form>

			{type === 'used' &&
				(isLoading ? (
					<Spin spinning />
				) : (
					<div className="w-full gap-2 flex flex-col">
						<h2>用量统计</h2>
						<LineComponent {...waterUsedConfig} height={400} />

						<LineComponent {...electronUsedConfig} height={400} />

						<h3>公共用电</h3>
						<ColumnComponent {...electronUsedGapConfig} />
					</div>
				))}

			{type === 'amount' && (
				<div className="w-full">
					<h2>营收统计</h2>
					{isLoading ? (
						<Spin spinning />
					) : (
						<LineComponent {...incomeConfig} height={400} />
					)}

					<h2>total 统计</h2>
					{isLoading ? (
						<Spin spinning />
					) : (
						<LineComponent {...allIncomeConfig} height={400} />
					)}
				</div>
			)}
		</Card>
	);
}
